import { useNapNegocios } from 'context';
import { IProductSede } from 'interfaces/Product';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { toFix } from 'utils/numbers';
import { usePriceContext } from './price';
import { useMoneyContext } from './money';
import { OrderTab } from 'interfaces/Sales';

interface ConfigCreditPay {
  isAfterPay: boolean;
  dueDate?: string;
}

const initialOrderState = {
  items: [],
  conceptos: [],
  customer: { _id: '' },
  discount: '0',
  prescription: { _id: '', name: '', customer: '' },
};

interface IContext {
  setModalPrintBluetooth: Function;
  modalPrintBluetooth: any;
  resetOrder: Function;
  fields: any[];
  setFields: Function;
  directPay: any;
  setDirectPay: Function;
  orderInfo: any;
  setOrderInfo: Function;
  allProductsSede: IProductSede[];
  setAllProductsSede: Function;
  clearOrder: () => void;
  changeCurrency: (e?: any) => void;
  currency: any;
  setCurrency: any;
  currencyData: any;
  setCurrencyData: any;
  configCreditPay: ConfigCreditPay;
  setConfigCreditPay: (e: ConfigCreditPay) => void;
  defaultPrice: string;
  setDefaultPrice: (e: string) => void;
  // Multi-tab related properties
  orderTabs: OrderTab[];
  activeTabId: string;
  addOrderTab: () => void;
  removeOrderTab: (tabId: string) => void;
  setActiveTabId: (tabId: string) => void;
}

export const OrderContext = createContext({} as IContext);
export const useOrderContext = () => useContext(OrderContext);

const OrderContextProvider = ({ children }: { children: ReactNode }) => {
  const { company, defaultCurrency } = useNapNegocios();
  const { getActualPriceByUnit, publicPrice } = usePriceContext();
  const { currenciesTree } = useMoneyContext();

  const [currency, setCurrency] = useState(company?.company?.currency?._id);
  const [currencyData, setCurrencyData] = useState(company?.company?.currency);

  const [orderInfo, setOrderInfo] = useState(structuredClone(initialOrderState));
  const [fields, setFields] = useState([]);
  const [directPay, setDirectPay] = useState(null);
  const [modalPrintBluetooth, setModalPrintBluetooth] = useState({
    isOpen: false,
    data: null as any,
  });
  const [allProductsSede, setAllProductsSede] = useState([]);
  const [configCreditPay, setConfigCreditPay] = useState<ConfigCreditPay>({ isAfterPay: false });
  const [defaultPrice, setDefaultPrice] = useState(publicPrice || '');

  // Nueva estructura para las pestañas de venta
  const [orderTabs, setOrderTabs] = useState<OrderTab[]>([
    {
      id: '1',
      name: 'Venta 1',
      order: structuredClone(initialOrderState),
      price: structuredClone(publicPrice || ''),
      currencyId: company?.company?.currency?._id,
      currencyData: company?.company?.currency,
      configCreditPay: { isAfterPay: false },
    },
  ]);

  const [activeTabId, setActiveTabId] = useState('1');

  const syncActiveTabWithOrderInfo = (tabId: string) => {
    const tab = orderTabs.find((t) => t.id === tabId);
    if (tab) {
      // Ensure we're properly cloning all properties including flags
      const clonedOrder = JSON.parse(JSON.stringify(tab.order));
      setOrderInfo(clonedOrder);
      setDefaultPrice(tab.price);
      setCurrency(tab.currencyId);
      setCurrencyData(tab.currencyData);
      setConfigCreditPay(tab.configCreditPay);
    }
  };

  // Sync order info back to active tab
  const syncOrderInfoWithActiveTab = () => {
    setOrderTabs((tabs) =>
      tabs.map((tab) =>
        tab.id === activeTabId
          ? {
              ...tab,
              order: JSON.parse(JSON.stringify(orderInfo)),
              price: defaultPrice,
              currencyId: currency,
              currencyData: currencyData,
              configCreditPay: configCreditPay,
            }
          : tab
      )
    );
  };

  // Add new order tab with consecutive numbering
  const addOrderTab = () => {
    // Save current tab state first
    syncOrderInfoWithActiveTab();

    // Determine the next tab number based on existing tabs
    const tabNumbers = orderTabs.map((tab) => parseInt(tab.id, 10));
    const nextTabNumber = tabNumbers.length > 0 ? Math.max(...tabNumbers) + 1 : 1;
    const newTabId = nextTabNumber.toString();

    const newTab: OrderTab = {
      id: newTabId,
      name: `Venta ${newTabId}`,
      order: structuredClone(initialOrderState),
      price: publicPrice || '',
      currencyId: company?.company?.currency?._id,
      currencyData: company?.company?.currency,
      configCreditPay: { isAfterPay: false },
    };

    // Always add new tabs at the end
    setOrderTabs((prev) => [...prev, newTab]);
    setActiveTabId(newTabId);

    // Reset the state for the new tab
    setOrderInfo(structuredClone(initialOrderState));
    setDefaultPrice(publicPrice || '');
    setCurrency(company?.company?.currency?._id);
    setCurrencyData(company?.company?.currency);
    setConfigCreditPay({ isAfterPay: false });
  };

  // Remove order tab, maintaining consecutive numbering
  const removeOrderTab = (tabId: string) => {
    // Never remove the first tab (Venta 1)
    if (tabId === '1') {
      return;
    }

    // Need at least one tab
    if (orderTabs.length <= 1) {
      return;
    }

    const newTabs = orderTabs.filter((tab) => tab.id !== tabId);
    setOrderTabs(newTabs);

    // If removing active tab, switch to first available tab
    if (tabId === activeTabId) {
      const newActiveId = newTabs[0].id;
      setActiveTabId(newActiveId);
      syncActiveTabWithOrderInfo(newActiveId);
    }
  };

  // When changing active tab
  const handleChangeActiveTab = (tabId: string) => {
    if (tabId === activeTabId) return;

    // Save current tab state
    syncOrderInfoWithActiveTab();

    // Set new active tab
    setActiveTabId(tabId);
    syncActiveTabWithOrderInfo(tabId);
  };

  // Override setOrderInfo to sync with active tab
  const setOrderInfoWithSync = (newOrderInfo: any) => {
    setOrderInfo(newOrderInfo);
  };

  const resetOrder = () => {
    if (company.reloadItemsAfterOrder) setFields([]);

    // Reset active tab
    const newOrderInfo = structuredClone(initialOrderState);
    setOrderInfo(newOrderInfo);

    // Update in tabs
    setOrderTabs((tabs) =>
      tabs.map((tab) => (tab.id === activeTabId ? { ...tab, order: structuredClone(newOrderInfo) } : tab))
    );
  };

  const clearOrder = () => {
    const currentTab = orderTabs.find((tab) => tab.id === activeTabId);

    // Keep the customer from the current order if it exists
    const preservedCustomer = currentTab?.order?.customer || { _id: '' };
    const preservedPrescription = currentTab?.order?.prescription || { _id: '', name: '', customer: '' };

    const newOrderInfo = {
      items: [],
      conceptos: [],
      customer: preservedCustomer,
      discount: '0',
      prescription: preservedPrescription,
    };

    setOrderInfo(newOrderInfo);

    // Don't reset the price if a customer with a specific price is selected
    const shouldKeepPrice = preservedCustomer?._id && preservedCustomer?.price?._id;
    if (!shouldKeepPrice) {
      setDefaultPrice(publicPrice || '');
    }

    setConfigCreditPay({ isAfterPay: false });

    // Update in tabs
    setOrderTabs((tabs) =>
      tabs.map((tab) =>
        tab.id === activeTabId
          ? {
              ...tab,
              order: structuredClone(newOrderInfo),
              price: shouldKeepPrice ? tab.price : publicPrice || '',
              configCreditPay: { isAfterPay: false },
            }
          : tab
      )
    );
  };

  const changeCurrency = async (newData?: any) => {
    const tempCurrencies = structuredClone(currenciesTree);
    if (newData) tempCurrencies[newData._id] = newData;

    const updatedOrderInfo = { ...orderInfo };

    updatedOrderInfo.items.forEach((item: any) => {
      // Skip recalculation if the price was manually modified
      if (item.isManuallyModified) {
        return;
      }

      const currencyItem = item.product?.currency;
      const currencyId = currencyItem?._id ?? defaultCurrency?._id;

      const factor =
        currencyId === currency
          ? 1
          : currenciesTree[currencyId]?.ventaValue ??
            currenciesTree[currencyId]?.value ??
            currencyItem?.ventaValue ??
            currencyItem?.value;

      const basePrice = getActualPriceByUnit(item, item.priceId);
      item.factor = factor;
      item.price = toFix(+basePrice * +factor);
      item.total = toFix(+item.quantity * +basePrice * +factor);
    });

    setOrderInfo(updatedOrderInfo);

    // Update in tabs - delay this update to avoid race conditions
    setTimeout(() => {
      setOrderTabs((tabs) =>
        tabs.map((tab) =>
          tab.id === activeTabId
            ? {
                ...tab,
                order: structuredClone(updatedOrderInfo),
                currencyId: currency,
                currencyData: currencyData,
              }
            : tab
        )
      );
    }, 0);
  };
  // Update context values when tab changes
  const setDefaultPriceWithSync = (price: string) => {
    setDefaultPrice(price);

    // Update in tabs
    setOrderTabs((tabs) => tabs.map((tab) => (tab.id === activeTabId ? { ...tab, price } : tab)));
  };

  const setConfigCreditPayWithSync = (config: ConfigCreditPay) => {
    setConfigCreditPay(config);

    // Update in tabs
    setOrderTabs((tabs) =>
      tabs.map((tab) => (tab.id === activeTabId ? { ...tab, configCreditPay: structuredClone(config) } : tab))
    );
  };

  // Ensure state is synced before unmounting or component updates
  useEffect(() => {
    return () => {
      syncOrderInfoWithActiveTab();
    };
  }, []);

  return (
    <OrderContext.Provider
      value={{
        setModalPrintBluetooth,
        modalPrintBluetooth,
        resetOrder,
        fields,
        setFields,
        directPay,
        setDirectPay,
        orderInfo,
        setOrderInfo: setOrderInfoWithSync,
        allProductsSede,
        setAllProductsSede,
        clearOrder,
        currency,
        setCurrency,
        currencyData,
        setCurrencyData,
        changeCurrency,
        configCreditPay,
        setConfigCreditPay: setConfigCreditPayWithSync,
        defaultPrice,
        setDefaultPrice: setDefaultPriceWithSync,
        // Multi-tab related properties
        orderTabs,
        activeTabId,
        addOrderTab,
        removeOrderTab,
        setActiveTabId: handleChangeActiveTab,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;
