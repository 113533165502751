import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNapNegocios } from 'context';
import { getProductPrices } from 'requests/products';
import { notification } from 'antd';
import { IPrice, IProduct } from 'interfaces/Product';

interface IPriceContext {
  load: boolean;
  data: IPrice[];
  reloadFunction: Function;
  getDataPriceById: (id: string) => IPrice | undefined;
  getActualPriceByUnit: (prd: any, id: string) => number;
  getActualPriceByUnitDefault: (prd: any) => number;
  publicPrice: string;
}

const PriceContext = createContext<IPriceContext>({} as any);
export const usePriceContext = () => useContext(PriceContext);

const PriceContextProvider = ({ children }: { children: ReactNode }) => {
  const { company, isAuth } = useNapNegocios();
  const [data, setData] = useState<IPrice[]>([]);
  const [load, setLoading] = useState(false);
  const [publicPrice, setPublicPrice] = useState('');

  useEffect(() => {
    if (isAuth) obtainData();
  }, [isAuth]);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getProductPrices(company._id);
      setData(res);

      const publicPrice = res.find((el: any) => el.name === 'PÚBLICO');
      setPublicPrice(publicPrice?._id ?? '');
    } catch (error) {
      notification.error({
        message: `Ocurrió un error al realizar la operación.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const getActualPriceByUnit = (product: IProduct, precioId: string) => {
    const findPrice = product?.unitInfo?.precios?.find((el: any) => el.description === precioId);
    if (!findPrice) return 0;

    return findPrice.value;
  };

  const getActualPriceByUnitDefault = (product: IProduct) => {
    const findPrice = product.unidades.find(
      (el: any) => el?._id === product.unitDefault || el.name === product.product?.unit?.label
    );

    const pricePublicId = data.find((el) => el.name === 'PÚBLICO')!;
    const returnPrice = findPrice?.precios.find((el: any) => el.description === pricePublicId?._id);

    if (!findPrice) return 0;

    return returnPrice?.value ?? 0;
  };

  const getDataPriceById = (id: string) => {
    const find = data.find((el) => el._id === id);
    return find;
  };

  return (
    <PriceContext.Provider
      value={{
        load,
        data,
        publicPrice,
        reloadFunction: obtainData,
        getDataPriceById,
        getActualPriceByUnit,
        getActualPriceByUnitDefault,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
};

export default PriceContextProvider;
