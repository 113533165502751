import { useNapNegocios } from 'context';
import useCurrencies from 'hooks/useCurrencies';
import { Currency } from 'interfaces/Currency';
import { createContext, useContext, ReactNode, useState, useEffect } from 'react';

export interface IMoneyContext {
  currencies: Currency[];
  generalCurrencies: Currency[];
  reloadCurrencies: () => void;
  type?: 'COMPRA' | 'VENTA';
  changeType: (e: 'COMPRA' | 'VENTA') => void;
  currency: any;
  setCurrency: any;
  currencyData: any;
  setCurrencyData: any;
  currenciesTree: any;
}

export const MoneyContext = createContext<IMoneyContext>({} as IMoneyContext);

/**
 * Hook para obtener la información del usuario y su sede actual
 * @return currencies - Monedas disponibles
 * @return currenciesTree - Monedas disponibles (árbol)
 * @return generalCurrencies - Monedas generales
 * @return selectCoin - Moneda seleccionada
 * @return type - COMPRA | VENTA
 * @return changeType - Cambiar el cambio COMPRA | VENTA
 * @return currency - Cambiar el cambio COMPRA | VENTA
 * @return setCurrency - Cambiar el cambio COMPRA | VENTA
 * @return currencyData - Cambiar el cambio COMPRA | VENTA
 * @return setCurrencyData - Cambiar el cambio COMPRA | VENTA
 */
export const useMoneyContext = (): IMoneyContext => useContext(MoneyContext);

const MoneyContextProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useNapNegocios();

  const [type, setType] = useState<'COMPRA' | 'VENTA'>('VENTA');
  const [currency, setCurrency] = useState(null);
  const [currencyData, setCurrencyData] = useState(null);
  const { currencies, reloadCurrencies, generalCurrencies, currenciesTree } = useCurrencies(type);

  const changeType = (typeCurrency: 'COMPRA' | 'VENTA') => setType(typeCurrency);

  useEffect(() => {
    if (type && isAuth) reloadCurrencies();
  }, [type, isAuth]);

  return (
    <MoneyContext.Provider
      value={{
        currencies,
        reloadCurrencies,
        type,
        changeType,
        currency,
        setCurrency,
        currencyData,
        setCurrencyData,
        generalCurrencies,
        currenciesTree,
      }}
    >
      {children}
    </MoneyContext.Provider>
  );
};
export default MoneyContextProvider;
