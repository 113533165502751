import { notification } from 'antd';
import { Currency } from 'interfaces/Currency';
import { useState } from 'react';
import { getCurrencies, getGeneralCurrencies } from 'requests/currency';

export default function useCurrencies(type?: string) {
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [generalCurrencies, setGeneralCurrencies] = useState<Currency[]>([]);
  const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(false);
  const [currenciesTree, setCurrenciesTree] = useState({});

  // useEffect(() => {
  //   fetchCurrencies();
  // }, []);

  const fetchCurrencies = async () => {
    try {
      setIsLoadingCurrencies(true);

      const [resTwo, res] = await Promise.all([getGeneralCurrencies(), getCurrencies(type)]);

      const temp: Record<string, any> = {};
      res.forEach((el: any) => (temp[el._id] = el));

      setCurrencies(res);
      setCurrenciesTree(temp);
      setGeneralCurrencies(resTwo);
    } catch (error: any) {
      notification.error({
        message: 'Error obteniendo métodos de pago',
        description: error.message,
      });
    } finally {
      setIsLoadingCurrencies(false);
    }
  };

  return { currencies, isLoadingCurrencies, reloadCurrencies: fetchCurrencies, generalCurrencies, currenciesTree };
}
