import { notification } from 'antd';
import { useNapNegocios } from 'context';
import { IRequestResponse } from 'interfaces/Transfer';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getTransferStock } from 'requests/tranfers';

interface IContext {
  myRequests: IRequestResponse[];
  incomingRequests: IRequestResponse[];
  historicRequests: IRequestResponse[];
  toSendRequests: IRequestResponse[];
  toReceiveRequests: IRequestResponse[];
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  obtainRequests: () => void;
}

export const RequestStockContext = createContext({} as IContext);
export const useRequestStockContext = () => useContext(RequestStockContext);

const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const { userInfo, company, isAuth } = useNapNegocios();

  const [isLoading, setIsLoading] = useState(false);

  const [myRequests, setMyRequests] = useState([] as IRequestResponse[]);
  const [incomingRequests, setIncomingRequests] = useState([] as IRequestResponse[]);
  const [historicRequests, setHistoricRequests] = useState([] as IRequestResponse[]);
  const [toSendRequests, setToSendRequests] = useState([] as IRequestResponse[]);
  const [toReceiveRequests, setToReceiveRequests] = useState([] as IRequestResponse[]);

  useEffect(() => {
    if (isAuth) obtainRequests();
  }, [userInfo]);

  const obtainRequests = async () => {
    if (!userInfo._id) return;

    setIsLoading(true);

    try {
      const res: {
        others: IRequestResponse[];
        send: IRequestResponse[];
        historic: IRequestResponse[];
        toSend: IRequestResponse[];
        toReceive: IRequestResponse[];
      } = await getTransferStock();

      if (company.company.requestKardexSimple)
        setMyRequests([...res.send?.filter((el) => el.status !== 'RECIBIDA'), ...res.toSend]);
      else setMyRequests(res.send?.filter((el) => el.status !== 'RECIBIDA'));

      setIncomingRequests(res.others);
      setHistoricRequests(res.historic);
      setToSendRequests(res.toSend);
      setToReceiveRequests(res.toReceive);
    } catch (error: any) {
      notification.error({
        message: 'Error',
        description: error.response.data.msg || 'Error al obtener las solicitudes',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    myRequests,
    incomingRequests,
    historicRequests,
    toSendRequests,
    toReceiveRequests,
    isLoading,
    setIsLoading,
    obtainRequests,
  };

  return <RequestStockContext.Provider value={value}>{children}</RequestStockContext.Provider>;
};

export default NotificationProvider;
