import { notification } from 'antd';
import { useNapNegocios } from 'context';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getPendingBilling } from 'requests/billing';
import { getCampusStatus } from 'requests/payments';

interface IContext {
  isOpenSideBarDesktop: boolean;
  setIsOpenSideBarDesktop: Function;
  drawerWidth: number;
  setDrawerWidth: Function;
  isOpenSideBarMobile: boolean;
  setIsOpenSideBarMobile: Function;
  sunatPending: number;
  infoPayment: {
    isActive: boolean;
    amount: number;
    description: string;
    quantity: number;
    dueDate: Date;
  };
}

const LayoutContext = createContext({} as IContext);
export const useLayoutContext = () => useContext(LayoutContext);

const LayoutContextProvider = ({ children }: { children: ReactNode }) => {
  const { userInfo, isAuth } = useNapNegocios();

  const [drawerWidth, setDrawerWidth] = useState(260);
  const [infoPayment, setInfoPayment] = useState<any>({});

  const [isOpenSideBarDesktop, setIsOpenSideBarDesktop] = useState(true);
  const [isOpenSideBarMobile, setIsOpenSideBarMobile] = useState(false);

  const [sunatPending, setSunatPending] = useState(0); // Cantidad de vouchers pendientes por enviar

  useEffect(() => {
    if (isAuth) {
      obtainData();
      validatePayments();
    }
  }, []);

  const obtainData = async () => {
    try {
      const res = await getPendingBilling();
      setSunatPending(res);
    } catch (error) {
      console.log(error);
    }
  };

  const validatePayments = async () => {
    try {
      const temp = await getCampusStatus(userInfo.roles ?? []);
      setInfoPayment(temp);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error al verificar los pagos.',
      });
    }
  };

  return (
    <LayoutContext.Provider
      value={{
        infoPayment,
        isOpenSideBarDesktop,
        setIsOpenSideBarDesktop,
        drawerWidth,
        setDrawerWidth,
        isOpenSideBarMobile,
        setIsOpenSideBarMobile,
        sunatPending,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;
