import { CAMPUS, NapNegociosAxios } from './config';

export const getGeneralCurrencies = async () => {
  const res = await NapNegociosAxios.get(`/currency/general`);
  return res.data;
};

export const getCurrencies = async (type?: string) => {
  const res = await NapNegociosAxios.get(`/currency/${CAMPUS}`, { params: { type } });
  return res.data;
};

export const postCurrency = async (data: any) => {
  const res = await NapNegociosAxios.post(`/currency/${CAMPUS}`, data);
  return res.data;
};

export const putCurrency = async (currencyId: string, data: any) => {
  const res = await NapNegociosAxios.put(`/currency/${currencyId}`, data);
  return res.data;
};

export const deleteCurrency = async (currencyId: string) => {
  const res = await NapNegociosAxios.delete(`/currency/${currencyId}`);
  return res.data;
};
